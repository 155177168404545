body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

p,
span,
strong,
ul,
li,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Ubuntu" !important;
}

.delivery-status .MuiSlider-valueLabel {
  background-color: wheat;
  color: #2c2c2c;
}

.signature-canvas {
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}
